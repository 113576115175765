import { sm2 } from 'sm-crypto'

export function sm2Encrypt(msgString, publicKey, cipherMode) {
  return sm2.doEncrypt(msgString, publicKey, cipherMode);
}

export function sm2Decrypt(encryptData, priKey, cipherMode) {
  return sm2.doDecrypt(encryptData, priKey, cipherMode);
}

export function sm2DoSignature (msg, privateKey) {
  return sm2.doSignature(msg, privateKey, {
    der: true,
    hash: true,
  });
}

export function sm2DoVerifySignature (msg, sigValueHex, publicKey) {
  return sm2.doVerifySignature(msg, sigValueHex, publicKey, {
    der: true,
    hash: true,
  });
}
