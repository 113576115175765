/**
 * Set storage
 *
 * @param name
 * @param content
 * @param maxAge
 */
export const setStore = (name, content, maxAge = null) => {
    if (!global.window || !name) {
        return;
    }

    if (typeof content !== 'string') {
        content = JSON.stringify(content)
    }

    let storage = global.window.localStorage

    // 清空后，再赋值
    clearStore(name);

    storage.setItem(name, content)
    if (maxAge && !isNaN(parseInt(maxAge))) {
        let timeout = parseInt(new Date().getTime() / 1000)
        storage.setItem(`${name}_expire`, timeout + maxAge)
    }
};

/**
 * Get storage
 *
 * @param name
 * @returns {*}
 */
export const getStore = name => {
    if (!global.window || !name) {
        return;
    }

    let content = window.localStorage.getItem(name)
    let _expire = window.localStorage.getItem(`${name}_expire`)

    if (_expire) {
        let now = parseInt(new Date().getTime() / 1000)
        if (now > _expire) {
            return;
        }
    }

    try {
        return JSON.parse(content)
    } catch (e) {
        return content
    }
};

export const setLocalStore = (name, content, maxAge = null) => {
    if (!global.window || !name) {
        return;
    }

    if (typeof content !== 'string') {
        content = JSON.stringify(content)
    }

    let storage = global.window.localStorage;

    // 清空后，再赋值
    clearStore(name);

    storage.setItem(name, content)
    if (maxAge && !isNaN(parseInt(maxAge))) {
        let timeout = parseInt(new Date().getTime() / 1000)
        storage.setItem(`${name}_expire`, timeout + maxAge)
    }
};

/**
 * Get storage
 *
 * @param name
 * @returns {*}
 */
export const getLocalStore = name => {
    if (!global.window || !name) {
        return;
    }

    let content = window.localStorage.getItem(name)
    let _expire = window.localStorage.getItem(`${name}_expire`)

    if (_expire) {
        let now = parseInt(new Date().getTime() / 1000)
        if (now > _expire) {
            return;
        }
    }

    try {
        return JSON.parse(content)
    } catch (e) {
        return content
    }
};

export const setAccessToken = (value) => {
    let hostname = getLocalStore('hostname');
    return setLocalStore(hostname + 'Token', value);
};

export const getAccessToken = () => {
    let hostname = getLocalStore('hostname');
    return getLocalStore(hostname + 'Token');
};
export const getsjzToken = () => {
    let userInfo = getUserInfo() || {}
    return userInfo.bindStatus === '01'
}

/**
 * Clear storage
 *
 * @param name
 */
export const clearStore = name => {
    if (!global.window || !name) {
        return;
    }

    if (getLocalStore(name)) {
        window.localStorage.removeItem(name);
        window.localStorage.removeItem(`${name}_expire`)
    }
};

/**
 * Clear all storage
 */
export const clearAll = () => {
    if (!global.window) {
        return;
    }
    window.localStorage.clear()
    
    if (!getLocalStore('hostname')) {
        setLocalStore('hostname', 'LIANMENG_WeChat');
    }
};

export const setUserInfo = (data) => {
    let name = getLocalStore('hostname') + '_userInfo';
    setLocalStore(name, data);
}

export const getUserInfo = () => {
    let name = getLocalStore('hostname') + '_userInfo';
    return getLocalStore(name)
}

export const setCurrentCompany = (data) => {
    let name = getLocalStore('hostname') + '_currentCompany';
    setLocalStore(name, data);
}

export const getCurrentCompany = () => {
    let name = getLocalStore('hostname') + '_currentCompany';
    return getLocalStore(name)
}

export const clearUserInfo = () => {
    let hostname = getLocalStore('hostname')
    clearStore(hostname + '_userInfo')
    clearStore(hostname + '_currentCompany')
    clearStore(hostname + '_token')
    clearStore(hostname + '_channelLogin')
}
