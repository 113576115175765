const Layout = () => import('../components/layouts/Layout');
const GlobalLayout = () => import('../components/layouts/GlobalLayout');
const Index = () => import('../pages/index/index');
const Login = () => import('../pages/login/login');
const Salary = () => import('../pages/salary/salary');
const SalaryList = () => import('../pages/salary/salaryList');
const Personal = () => import('../pages/personal/personal');
const Company = () => import('../pages/company/companyInfo');
const Error = () => import('../pages/error/error');
const Console = () => import('../pages/console');
const Settings = () => import('../pages/settings/settings');

// 联系我们
const Contact = () => import('../pages/contact/contactus')
//招贤纳士
const Recruit = () => import('../pages/Recruit/recruit')
const Recruitdetail = () => import('../pages/Recruit/recruitdetail')

//服务案例
const Case = () => import('../pages/servercase/servercase')
const Servercasedetail = () => import('../pages/servercase/servercasedetail')

//产品中心
const Products = () => import('../pages/pruducts/products')
const Productsdetail = () => import('../pages/pruducts/productsdetail')
//公司荣誉
const Honor = () => import('../pages/honor/honor')
//公司简介
const Bussbrief  = ()=>import('../pages/bussintroduction/bussintroduction')

//新闻中心
const Newcenter = ()=>import('../pages/newscenter/newscenter')
const Newcenterdetail = ()=>import('../pages/newscenter/newcenterdetail')

/** 工作台 */
const Workbench = () => import('../pages/workbench/workbench')
import collaborativeOfficeRoutr from './modules/workbench/collaborativeOffice' // 协同办公
import personnelManagementRoutr from './modules/workbench/personnelManagement' // 人事管理
import financialManagementRoutr from './modules/workbench/financialManagement' // 财务管理
import otherRoutr from './modules/workbench/other' // 其他

export const constantRouterMap = [
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/Error',
        name: 'Error',
        component: Error
    },
    {
        path: '/Console',
        name: 'Console',
        component: Console
    },
    {
        path: '/',
        component: GlobalLayout,
        redirect: {
            name: 'index'
        },
        children: [
            {
                path: '/index',
                name: 'index',
                component: Index
            },
            {
                path: '/personal',
                name: 'personal',
                component: Personal
            },
            {
                path: '/contactus',
                name: 'contactus',
                component: Contact
            },
            {
                path: '/recruit',
                name: 'recruit',
                component: Recruit,
            },
            {

                path: '/recruit/detail',
                name: 'recruitdetail',
                component: Recruitdetail

            },
            {
                path: '/case',
                name: 'case',
                component: Case,
            },
            {
                path: '/casedetail',
                name: 'servercasedetail',
                component: Servercasedetail
            },
            {
                path: '/products',
                name: 'products',
                component: Products,
            },
            {
                path: '/products/detail',
                name: 'productsdetail',
                component: Productsdetail,
            },
            {
                path: 'bussintroduction',
                name: 'bussintroduction',
                component:Bussbrief,
            },
            {
                path: '/honor',
                name: 'honor',
                component: Honor,
            },
            {
                path: '/newcenter',
                name: 'newcenter',
                component: Newcenter,
            },
            {
                path: '/newcenterdetail',
                name: 'newcenterdetail',
                component: Newcenterdetail,
            },

            {
                path: '/workbench', //工作台
                name: 'workbench',
                component: Workbench
            }
        ]
    },
    // {
    //     path: '/',
    //     component: Layout,
    //     children: [
    //         // ...collaborativeOfficeRoutr, //协同办公
    //         // ...personnelManagementRoutr, //人事管理
    //         // ...financialManagementRoutr, //财务管理
    //         // ...otherRoutr, //其他
    //         // {
    //         //     path: '/checkPwd',
    //         //     name: 'checkPwd',
    //         //     component: CheckPwd,
    //         // },         
    //     ]
    // }
]