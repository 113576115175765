import Vue from 'vue'
import {ACCESS_TOKEN, USER_NAME, USER_INFO} from "@/store/mutation-types"
import {queryPermissionsByUser} from '@/api/api' //查询用户拥有的菜单权限和按钮权限（根据TOKEN）
import {getAction} from '@/api/manage'

const user = {
    state: {
        token: '',
        username: '',
        realname: '',
        avatar: '',
        permissionList: [],
        info: {}
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        SET_PERMISSIONLIST: (state, permissionList) => {
            state.permissionList = permissionList
        },
        SET_INFO: (state, info) => {
            state.info = info
        },
    },

    actions: {
        // CAS验证登录
        ValidateLogin({commit}, userInfo) {
            return new Promise((resolve, reject) => {
                getAction("/cas/client/validateLogin", userInfo).then(response => {
                    console.log("----cas 登录--------", response);
                    if (response.success) {
                        const result = response.result
                        userInfo = result.userinfo
                        Vue.ls.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
                        Vue.ls.set(USER_NAME, userInfo.username, 7 * 24 * 60 * 60 * 1000)
                        Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000)
                        commit('SET_TOKEN', result.token)
                        commit('SET_INFO', userInfo)
                        commit('SET_NAME', {
                            username: userInfo.username,
                            realname: userInfo.realname
                        })
                        commit('SET_AVATAR', userInfo.avatar)
                        resolve(response)
                    } else {
                        resolve(response)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 获取用户信息
        GetPermissionList({commit}) {
            return new Promise((resolve, reject) => {
                let v_token = Vue.ls.get(ACCESS_TOKEN);
                let params = {token: v_token};
                queryPermissionsByUser(params).then(response => {
                    if (!response.success) {
                        console.error(response.message);
                        reject(response.message);
                        return false;
                    }
                    console.log(commit);
                    const menuData = response.data.menu;
                    // const authData = response.data.auth;
                    // const allAuthData = response.data.allAuth;
                    // sessionStorage.setItem(USER_AUTH, JSON.stringify(authData));
                    // sessionStorage.setItem(SYS_BUTTON_AUTH, JSON.stringify(allAuthData));

                    if (menuData && menuData.length > 0) {
                        commit('SET_PERMISSIONLIST', menuData)
                    } else {
                        reject('getPermissionList: permissions must be a non-null array !')
                    }

                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }

    }
}

export default user