<template>
    <a-config-provider :locale="locale">
        <div id="app">
            <router-view></router-view>
        </div>
    </a-config-provider>
</template>

<script>
import store from './store'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
    name: 'app',
    data() {
        return {
            locale: zhCN
        }
    },
    mounted() {
        window.addEventListener('scroll', () => {
            // 获取滚动位置
            const scrollPosition = window.scrollY;
            const threshold = 100
            if (scrollPosition > threshold) {
                store.commit('SET_IS_STICKY', true);
            } else {
                store.commit('SET_IS_STICKY', false);
            }
        });
    },

    beforeDestroy() {
        window.removeEventListener('scroll');
    },
}
</script>

<style>
@media screen and (min-width: 768px) {
    /* body{
        background-image: url(./assets/官网切图/官网-背景.png)
    } */
}
*{
    margin: 0;
    padding: 0;
    list-style: none;
    box-sizing: border-box;
}
body,html{
    width: 100%;
    height: 100%;
}
#app {
    width: 100%;
    height: 100%;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
</style>
