import axios from 'axios'
import { aes, sha256, base64 } from '@/utils/encryption/aesEncrypt';
import { VueAxios } from './axios'
import { message } from 'ant-design-vue'
import { getUserInfo, getAccessToken, clearUserInfo, clearStore, setAccessToken } from '@/utils/storage'

import {sm4Encrypt,sm4Decrypt,getSM4Key} from './encryption/sm4-utils'
import {sm2Encrypt,sm2Decrypt,sm2DoSignature,sm2DoVerifySignature} from './encryption/sm2-utils'
import { timeStamp, guid,sign,md5 } from './encryption/Encrypt'
const sm3 = require('sm-crypto').sm3

// 刷新token后的重试队列，每一项将是一个待执行的函数形式
let requests = [];

// 创建 axios 实例
const service = axios.create({
    // 需要配置网关地址，如果前端项目的部署地址和访问的网关地址不同，修改下面这句网址
    // baseURL: process.env.NODE_ENV === 'development' ? '/dfy' : sessionStorage.getItem('basePath') + 'dfy',
    baseURL: '',
    timeout: 20000 // 请求超时时间
})

const cipherMode = 1;
const publicKey ='049F216C832AC26837331953F83542BF623900D6432A01CA61B1CB346A430E74CF40F4B6D6B8D3010504523E21A80C42A19A620A641D117397F43DBD7EAAA57F5A'
const privateKey ='043A1D63430C37C0004AD925C5BC8B4B4B00065E407D3AFF56AF91AFD6EF90CA30B2A28E1746375AE2CD341A30935942A6F5215A9590CE4A034162A052393C03F4'
//验签用公钥，加密用公钥 

const usersignprivateKey = '434500b482ff34a163c9244bc465b6061257d057f0332b036cbead2bf67a99c8'

const userdeprivateKey = 'abd5c86670cdb3a9385349c493dddaeb6552aa91acf62f4567f80767c95045ae'

const err = (error) => {
    console.log(error.response);
    if (error.response) {
        let data = error.response.data;
        console.log(error.response.status)
        // if (data.data) {
        //     data = JSON.parse(aes.de(data.data));
        // }

        let resSign = Object.assign({}, error.response.data)
            delete resSign['sign'];
    
            resSign = sign(resSign)
    
            let str = JSON.stringify(resSign)
            str = str.replace(/[{}"]/g,'').replace(/:/g, '=').replace(/,/g, '&')
            str = str.replace(/####/g, ':')
            str=sm3(str)
            let checkSign = sm2DoVerifySignature(str, error.response.data.sign, publicKey)
            if(checkSign){
                //获取密钥
                let encodeKey = error.response.data.encodeKey.slice(2)
                let sm4deKey = sm2Decrypt(encodeKey.toLocaleLowerCase(), userdeprivateKey, cipherMode)
                let res = JSON.parse(sm4Decrypt(error.response.data.responseData, sm4deKey)) //sm4解密
                console.log('解密数据===========',res)
                error.response.data = res
            }
        switch (error.response.status) {
            case 403:
                message.error('拒绝访问')
                break
            case 500:
                message.error('服务错误，请稍后重试')
                break
            case 404:
                message.error('很抱歉，资源未找到!')
                break
            case 504:
                message.error('网络超时')
                break
            case 451:
                message.error('无权访问')
                break
            case 300:
            case 401:
                let newToken = ''
                // 正在刷新token，返回一个未执行resolve的promise
                new Promise((resolve) => {
                    // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
                    requests.push((token) => {
                        config.headers.Authorization = `Bearer ${token}`;
                        resolve(service(config))
                    });
                });

                let Authorization = error.response.config.headers["Authorization"]
                if (Authorization && Authorization != 'undefined') {
                    var config = error.response.config;
                    
                    setAccessToken(data.token)
                    try {
                        newToken = data.token
                        // 为了解决换Tocken导致的请求路径错误问题，出现两个 config.baseURL
                        if (config.baseURL) {
                            if (new RegExp('^' + config.baseURL).test(config.url)) {
                                config.url = config.url.replace(config.baseURL, '');
                            }
                        }
                        return service(config);
                    } catch (e) {
                    } finally {
                        requests.forEach(cb => cb(newToken));
                        // 重试完了别忘了清空这个队列
                        requests = [];
                    }
                } else {
                    message.warning(data.message + ', 将要退出当前页面', 5, () => {
                        clearUserInfo();
                        //关闭安卓系统手机
						document.addEventListener(
							'WeixinJSBridgeReady',
							function(){
								WeixinJSBridge.call('closeWindow')
                            },
                            false
						)
                        WeixinJSBridge.call('closeWindow')
                    });
                }
                break;
            default:
                // 用户不存在
                if (data.code === '100000') {
                    message.error(data.message, () => {
                        clearUserInfo()
                        window.location.reload();
                    })
                } else {
                    message.error(data.error ? '服务不可用，请稍后再试' : data.message);
                }
                break
        }
    }
    console.error(error);
    return Promise.reject(error)
};

// request interceptor
service.interceptors.request.use(config => {
    const token = getAccessToken();
    if (token) {
        config.headers['Authorization'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
    }
    
    let userInfo = getUserInfo() || {}
    const unionId = userInfo.unionid || '';
    if (unionId) {
        config.headers['UNION_ID'] = unionId;
    }

    if (config.method == 'get') {
        // if (config.url.indexOf("sys/dict/getDictItems") < 0) {
        //     config.params = {
        //         _t: Date.parse(new Date()) / 1000,
        //         ...config.params
        //     }
        // }
        config.data = {
            unsed:0
        }
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded' 
    }

    console.log('request.... ', config.url);
    console.log(config.data);
    // config.headers['IDENTIFICATION'] = "c*V@cc7!8fiTM~0G^d>>FVx6K]2cpV=@"

    // 加密数据
    let encryptdata = ''
    let ct = +new Date()
    let sign = 'ct' + ct +'jpressAppId' + 'qianhedianzi' + 'qianhedianzi'
    if (typeof (config.data) == "object") { //判断是否401 如果是则不加密
        if (!/^\/upload/.test(config.url)) {
           
            // config.data.jpressAppId='qianhedianzi'
            // config.data.ct= ct
            // config.data.sin=md5(sign)
        }
    }
    console.log(config.data)
    return config
}, (error) => {
    return Promise.reject(error)
})

function showService(config) {
    console.log('response=== ' + config.config.url, ' response : ');
    if (config.data.success) {
        console.log(config.data.data)
    } else {
        console.log('errorMsg====', config.data.message);
        try {
            throw new Error(config.data.message);
        } catch (e) { }
    }
}

// response interceptor
service.interceptors.response.use((response) => {
    // let checkSign = base64.en(sha256(response.data.data))
    // if (checkSign == response.data.sign) {
    //     response.data = JSON.parse(aes.de(response.data.data))
    // }
    let resSign = Object.assign({}, response.data)
        delete resSign['sign'];

        resSign = sign(resSign)

        let str = JSON.stringify(resSign)
        str = str.replace(/[{}"]/g,'').replace(/:/g, '=').replace(/,/g, '&')
        str = str.replace(/####/g, ':')
        str=sm3(str)
        //let checkSign = sm2DoVerifySignature(str, response.data.sign, publicKey)
        // if(checkSign){
        //     //获取密钥
        //     let encodeKey = response.data.encodeKey.slice(2)
        //     let sm4deKey = sm2Decrypt(encodeKey.toLocaleLowerCase(), userdeprivateKey, cipherMode)
        //     let res = JSON.parse(sm4Decrypt(response.data.responseData, sm4deKey)) //sm4解密
        //     console.log('解密数据===========',res)
        //     response.data = res
        // }
    showService(response);
    return response.data;
}, err);

const installer = {
    vm: {},
    install(Vue, router = {}) {
        Vue.use(VueAxios, router, service)
    }
}

export {
    installer as VueAxios,
    service as axios
}
