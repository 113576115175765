import Vue from 'vue'
import App from './App.vue'
import Storage from 'vue-ls'
import VueRouter from 'vue-router'
import router from './router'
import store from './store/'
import VueTouch from 'vue-touch'
import Antd from 'ant-design-vue'
// import Viser from 'viser-vue'
import 'ant-design-vue/dist/antd.less';
import { Popup, Picker, List, PullRefresh, Icon } from 'vant';
import 'vant/lib/index.css';

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

//import './permission'
import { VueAxios } from "@/utils/request"
require('@/utils/fastClick')
require('@/utils/pwdboard/pwd')

import {
    ACCESS_TOKEN
} from "@/store/mutation-types"
import config from '@/defaultSettings'
import '@/utils/pxToRem.js'

Vue.config.productionTip = false

Vue.use(ElementUI)
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
Vue.use(VueDirectiveImagePreviewer)

//Vue.use(Viewer) 默认配置写法
Vue.use(Viewer, {
    defaultOptions: {
        zIndex: 9999
    }
})


Vue.use(Antd);
// Vue.use(Viser)
Vue.use(Popup).use(Picker).use(Icon).use(PullRefresh).use(List)
// Vue.use(Popup);
// Vue.use(Picker);
Vue.use(Storage, config.storageOptions)
Vue.use(VueRouter)
Vue.use(VueAxios, router)
Vue.prototype.sjzFlag = localStorage.getItem('sjzFlag') === 'mock'


// Vue.use(VueTouch, {name: 'v-touch'})
// VueTouch.config.swipe = {
//     threshold: 50  //设置左右滑动的距离
// };

new Vue({
    router,
    store,
    mounted() {
        store.commit('SET_TOKEN', Vue.ls.get(ACCESS_TOKEN))
    },
    render: h => h(App),
}).$mount('#app')
