import CryptoJS from 'crypto-js';
import moment from 'moment';
// MD5
const md5 = (data) => {
    return CryptoJS.MD5(data).toString();
};

// BASE64
const base64 = {
    en: (data) => CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data)),
    de: (data) => CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8)
};

const sign = ( data) => {//token, timestamp,  //最终排序内容为字符串
    for (var i in data) {
        var value = data[i];
        if(data.hasOwnProperty("sign")){
            delete data['sign']
        }
        
        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                if (value.length == 0) {
                    delete data[i];
                    continue;
                }
            }
            // this.deleteEmptyProperty(value);
        } else {
            if (value === '' || value === null || value === undefined) {
                delete data[i];
            }
        }
    }
    var newkey = Object.keys(data).sort();
    var newObj = {}; //创建一个新的对象，用于存放排好序的键值对
    for(let i = 0; i < newkey.length; i++) {
        //遍历newkey数组
        newObj[newkey[i]] = data[newkey[i]];
        //向新创建的对象中按照排好的顺序依次增加键值对

    }
    return newObj;
};

const guid =()=> { //生成uuid
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = Math.random() * 16 | 0,
			v = c == 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
}
const gettimeStamp =()=> { //生成时间戳
    let dateTime = moment().locale('zh-cn').format('YYYY-MM-DD HH:mm:ss')
    let millise=new Date().getMilliseconds()
    return dateTime.toString()+'.'+millise.toString()
}



const timeStamp =  gettimeStamp()
export {
    md5,
    base64,
    sign,
    guid,
    timeStamp
};
