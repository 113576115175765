import { axios } from '@/utils/request'
import { md5 } from '../utils/encryption/Encrypt'


const api = {
  user: '/api/user',
  role: '/api/role',
  service: '/api/service',
  permission: '/api/permission',
  permissionNoPager: '/api/permission/no-pager'
};

export default api

//post
export function postAction(url, parameter, headers) {
  let ct = +new Date()
  let sign = md5('ct' + ct + 'jpressAppId' + 'qianhedianzi' + 'qianhedianzi')
  let ul = '?jpressAppId=qianhedianzi&ct=' + ct + '&sign=' + sign
  // parameter.jpressAppId='qianhedianzi'
  // parameter.ct=ct
  // parameter.sign=sign
  return axios({
    url: url + ul,
    method: 'post',
    data: parameter,
    transformRequest: [function (data) { 
      var ret = '';
      for (var it in data) {
          // 如果要发送中文 编码
          ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
      }
      return ret.substring(0,ret.length-1)
    }],
    headers: headers
  })
}

//post method= {post | put}
export function httpAction(url, parameter, method) {
  return axios({
    url: url,
    method: method,
    data: parameter
  })
}

//put
export function putAction(url, parameter) {
  return axios({
    url: url,
    method: 'put',
    data: parameter
  })
}

//get
export function getAction(url, parameter) {
  let ct = +new Date()
  let sign = md5('ct' + ct + 'jpressAppId' + 'qianhedianzi' + 'qianhedianzi')
  let sign1 = md5(url)
  let ul = '?jpressAppId=qianhedianzi&ct=' + ct + '&sign=' + sign
  // parameter.jpressAppId='qianhedianzi'
  // parameter.ct=ct
  // parameter.sign=sign
  return axios({
    url: url + ul,
    method: 'get',
    params: parameter,
  })
}

//deleteAction
export function deleteAction(url, parameter) {
  return axios({
    url: url,
    method: 'delete',
    params: parameter
  })
}

export function getUserList(parameter) {
  return axios({
    url: api.user,
    method: 'get',
    params: parameter
  })
}

export function getRoleList(parameter) {
  return axios({
    url: api.role,
    method: 'get',
    params: parameter
  })
}

export function getServiceList(parameter) {
  return axios({
    url: api.service,
    method: 'get',
    params: parameter
  })
}

export function getPermissions(parameter) {
  return axios({
    url: api.permissionNoPager,
    method: 'get',
    params: parameter
  })
}

// id == 0 add     post
// id != 0 update  put
export function saveService(parameter) {
  return axios({
    url: api.service,
    method: parameter.id == 0 ? 'post' : 'put',
    data: parameter
  })
}

/**
 * 下载文件 用于excel导出
 * @param url
 * @param parameter
 * @returns {*}
 */
export function downFile(url, parameter) {
  return axios({
    url: url,
    params: parameter,
    method: 'get',
    responseType: 'blob'
  })
}

