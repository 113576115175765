import { getAction} from '@/api/manage' //,deleteAction,putAction,postAction


//权限管理
const getPermissionList = (params)=>getAction("/sys/permission/list",params);
const queryPermissionsByUser = (params)=>getAction("/sys/permission/getUserPermission",params);

export {
    getPermissionList,
    queryPermissionsByUser
}